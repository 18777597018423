import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

let getHomepageEn = () => {
  return store.getters.getHomepage.filter(page => page.locale === "en")
}

let getHomepageEs = () => {
  return store.getters.getHomepage.filter(page => page.locale === "es-US")
}

let getFaqCategoriesEn = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "en")
}

let getFaqCategoriesEs = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "es-US")
}

let getFaqsEn = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "en")
}

let getFaqsEs = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "es-US")
}

let getFormCategoriesEn = () => {
  return store.getters.getFormCategory.filter(cat => cat.locale === "en")
}

let getFormCategoriesEs = () => {
  return store.getters.getFormCategory.filter(cat => cat.locale === "es-US")
}

let getFormPackets = () => {
  return store.getters.getFormPackets
}


let getFormsEn = () => {
  let temp = []
  store.getters.getForms.forEach(form => {
    if (form.locale === 'en') {
      temp.push(form)
    }
  })
  return temp
}

let getFormsEs = () => {
  let temp = []
  store.getters.getForms.forEach(form => {
    if (form.locale === 'es-US') {
      temp.push(form)
    }
  })
  return temp
}

let getMapsEn = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === 'en') {
      temp.push(map)
    }
  })
  return temp
}

let getMapsEs = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === 'es-US') {
      temp.push(map)
    }
  })
  return temp
}

let getFloorsEn = () => {
  return store.getters.getFloors.filter(cat => cat.locale === "en")
}
let getFloorsEs = () => {
  return store.getters.getFloors.filter(cat => cat.locale === "es-US")
}

let getQnaEn = () => {
  return store.getters.getQnaEndpointEN
}
let getQnaEs = () => {
  return store.getters.getQnaEndpointES
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      // Main Page
      homepages: getHomepageEn,

      // Faqs
      faqCategory: getFaqCategoriesEn,
      faqs: getFaqsEn,

      // Forms
      formCategory: getFormCategoriesEn,
      forms: getFormsEn,
      formPackets: getFormPackets,

      // Maps
      maps: getMapsEn,
      floors: getFloorsEn,

      //QnA Endpoint
      qnaEndpoint: getQnaEn,
    },
    es: {
      // Main Page
      homepages: getHomepageEs,

      // Faqs
      faqCategory: getFaqCategoriesEs,
      faqs: getFaqsEs,

      // Forms
      formCategory: getFormCategoriesEs,
      forms: getFormsEs,
      formPackets: getFormPackets,

      // Maps
      maps: getMapsEs,
      floors: getFloorsEs,

      //QnA Endpoint
      qnaEndpoint: getQnaEs,
    }
  }
})
