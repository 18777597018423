import router from "@/router";
import i18n from "../../i18n";
import axios from "axios";

export default {
    state: {
        qnaStatus: null,
        qnaKeyword: '',
        wrongAnswerCount: 0,
        court: '',
        isThinking: false,
        requestQna: true
    },
    getters: {
        getQnaStatus: state => state.qnaStatus,
        getQnaKeyword: state => state.qnaKeyword,
        getWrongAnsCount: state => state.wrongAnswerCount,
        getCourt: state => state.court,
        getIsThinking: state => state.isThinking,
        getRequestQna: state => state.requestQna
    },
    mutations: {
        setQnaStatus(state, status) {
            state.qnaStatus = status;
        },
        setQnaKeyword(state, keyword) {
            state.qnaKeyword = keyword
        },
        setWrongAnswerCount(state, count) {
            state.wrongAnswerCount = count
        },
        setCourt(state, court) {
            state.court = court
        },
        setIsThinking(state, status) {
            state.isThinking = status
        },
        setRequestQna(state, status) {
            state.requestQna = status
        }
    },
    actions: {
        requestQnA({ getters, dispatch, commit }, transcript) {
            return new Promise((response, reject) => {
                if (getters.isThinking) {
                    commit("setSpeechTranscript", transcript);
                    commit("setTextBubbleText", transcript);
                }
                commit('setQnaStatus', 'Thinking')
                let data = JSON.stringify({
                    'question': transcript,
                    'top': 3,
                    'includeUnstructuredSources': true,
                    'confidenceScoreThreshold': '0.10'
                })

                if (typeof transcript === 'object') {
                    data = JSON.stringify({
                        'question': transcript.displayText,
                        'top': 3,
                        'scoreThreshold': 10,
                        'rankerType': 'QuestionOnly',
                        'qnaId': transcript.qnaId
                    })
                }

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: i18n.t('qnaEndpoint').kbName,
                    headers: {
                        'Ocp-Apim-Subscription-Key': i18n.t('qnaEndpoint').key,
                        'Content-Type': 'application/json'
                    },
                    data: data
                }
                commit("setIsThinking", true)
                axios.request(config).then(response => {
                    // Logging the questions asked to the AI
                    const qnaAnalytics = {
                        timeStamp: new Date(),
                        kioskID: getters.getKioskId,
                        transcript: transcript,
                        result: response.data,
                    }
                    //dispatch('sendQnaData', qnaAnalytics);
                    dispatch('addQnaDatatoStrapi', qnaAnalytics);
                    // Logging the kiosk utilization
                    commit('setTouchRequest', {
                        module: 'QnA',
                        action: transcript,
                        response: response.data.answers[0].answer,
                        timeStamp: new Date(),
                        requestType: 'Speech'
                    })

                    if (response.data.answers[0].confidenceScore > 0.6) {
                        dispatch('generateAnswer', response.data.answers[0])
                    } else if (response.data.answers[0].confidenceScore > 0.45) {
                        commit("setIsThinking", false)
                        if (getters.getCurrentLanguage === 'en-US') {
                            dispatch('avatarSpeak', 'I didn\'t quite get that, did you mean to ask following questions. Please tap your preference.')
                        } else {
                            dispatch('avatarSpeak', 'No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia.')
                        }
                        let followUp = [];
                        response.data.answers.forEach(output => {
                            if (output.confidenceScore > 0.45)
                                followUp.push(output.questions[0])
                        });
                        var headerVal;
                        if (getters.getCurrentLanguage === 'en-US') {
                            headerVal = 'Please Tap the option you prefer'
                        } else {
                            headerVal = 'Por favor, toque la opción que prefiera'
                        }
                        dispatch('openOptionsViewer', {
                            dialogHeader: headerVal,
                            dialogData: followUp,
                        })
                    } else if (response.data.answers[0].id === -1) {
                        commit("setIsThinking", false)
                        getters.getMaps.forEach(map => {
                            if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                                dispatch('openImageViewer', {
                                    name: map.mapName,
                                    url: map.mapImage
                                })
                            }
                        })
                        if (getters.getCurrentLanguage === 'en-US') {
                            dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                        } else {
                            dispatch('avatarSpeak', 'Lo siento. No estoy seguro de cómo responder esta pregunta. Por favor, hazme una pregunta diferente, o puedes continuar usando la interfaz táctil. Además, puede obtener más información en la ubicación rodeada de rojo.')
                        }
                    } else {
                        commit("setIsThinking", false)
                        if (getters.getWrongAnsCount === 0) {
                            commit('setWrongAnswerCount', getters.getWrongAnsCount + 1);
                            if (getters.getCurrentLanguage === 'en-US') {
                                dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Please try asking different question.')
                            } else {
                                dispatch('avatarSpeak', '¡Lo siento! Acabo de empezar a trabajar aquí, pronto sabré respuestas a todas las preguntas relacionadas con la corte. Por favor, intenta hacer otra pregunta.')
                            }
                        } else if (getters.getWrongAnsCount === 1) {
                            commit('setWrongAnswerCount', getters.getWrongAnsCount + 1);
                            if (getters.getCurrentLanguage === 'en-US') {
                                dispatch('avatarSpeak', 'Sorry! I don\'t know the answer to this. I will make sure it will be there next time you ask. For now, please try asking different question.')
                            } else {
                                dispatch('avatarSpeak', '¡Lo siento! No sé la respuesta a esto. Me aseguraré de que estará ahí la próxima vez que preguntes. Por ahora, por favor trate de hacer otra pregunta.')
                            }
                        } else if (getters.getWrongAnsCount === 2) {
                            commit('setWrongAnswerCount', 0);
                            getters.getMaps.forEach(map => {
                                if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                                    dispatch('openImageViewer', {
                                        name: map.mapName,
                                        url: map.mapImage
                                    })
                                }
                            })
                            if (getters.getCurrentLanguage === 'en-US') {
                                dispatch('avatarSpeak', 'Sorry I’m still learning. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                            } else {
                                dispatch('avatarSpeak', 'Lo siento, todavía estoy aprendiendo. Por favor, hazme una pregunta diferente, o puedes continuar usando la interfaz táctil. Además, puede obtener más información en la ubicación rodeada de rojo.')
                            }
                        }
                    }
                })
            })
        },
        generateAnswer({ dispatch, commit, getters }, result) {
            commit('setQnaStatus', false)
            const metadata = result.metadata.intent
            const ansObj = result
            commit('setDefaultBubbleText', false)
            commit('closeOptionsViewer')
            switch (metadata) {
                case "navigate":
                    var menuName = ansObj.metadata.navigateto
                    router.addRoute({ path: menuName.toLowerCase(), component: () => import('@/views/' + menuName) });
                    if (router.currentRoute.path !== menuName) {
                        router.push(menuName).then(() => {
                            commit('setQnaKeyword', '')
                            dispatch('avatarSpeak', ansObj.answer);
                            let page = i18n.t('homepages').filter(page => page.pageType[0].pathName === menuName)
                            commit('setPageHeader', page[0].pageType[0].pageHeader)
                        })
                    } else {
                        commit('setQnaKeyword', '')
                        dispatch('avatarSpeak', ansObj.answer)
                    }
                    break;

                case "showmap":

                    try {
                        var mapPath
                        let mapName = ansObj.metadata.mappath
                        getters.getMaps.forEach(map => {
                            if (map.mapName.toLowerCase() === mapName) {
                                mapPath = map
                            }
                        })
                        dispatch('openImageViewer', {
                            name: mapPath.mapName,
                            url: mapPath.mapImage
                        })
                        dispatch('avatarSpeak', ansObj.answer);


                    } catch (e) {
                        console.log(e.message)
                        getters.getMaps.forEach(map => {
                            if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                                dispatch('openImageViewer', {
                                    name: map.mapName,
                                    url: map.mapImage
                                })
                            }
                        })

                        //dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Clerk’s Office located here.')
                        if (getters.getCurrentLanguage === 'en-US') {
                            dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                        } else {
                            dispatch('avatarSpeak', 'Lo siento. No estoy seguro de cómo responder esta pregunta. Por favor, hazme una pregunta diferente, o puedes continuar usando la interfaz táctil. Además, puede obtener más información en la ubicación rodeada de rojo.')
                        }
                    }
                    break;

                case "speak":

                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showform":
                    try {
                        var formName = ansObj.metadata.formname
                        i18n.t('forms').forEach(form => {
                            if (form.formName.toLowerCase() === formName) {
                                dispatch('openPdfViewer', {
                                    url: form.formPdf,
                                    name: form.formName,
                                    emailLink: form.pdfUrl,
                                    type: 'Form'
                                })
                                console.log('Coming here in if')
                                dispatch('avatarSpeak', ansObj.answer);
                            } else {
                                // Only english form available
                                console.log('Coming here in else')
                                getters.getForms.forEach(form => {
                                    if (form.formName.toLowerCase() === formName) {
                                        dispatch('openPdfViewer', {
                                            url: form.formPdf,
                                            name: form.formName,
                                            emailLink: form.pdfUrl,
                                            type: 'Form'
                                        })
                                        dispatch('avatarSpeak', ansObj.answer);
                                    }
                                })
                            }
                        })
                    } catch (e) {
                        console.log(e.message)
                        getters.getMaps.forEach(map => {
                            if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                                dispatch('openImageViewer', {
                                    name: map.mapName,
                                    url: map.mapImage
                                })
                            }
                        })
                        //dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Pleas try asking different question or visit Clerk\'s office.');
                        if (getters.getCurrentLanguage === 'en-US') {
                            dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                        } else {
                            dispatch('avatarSpeak', 'Lo siento. No estoy seguro de cómo responder esta pregunta. Por favor, hazme una pregunta diferente, o puedes continuar usando la interfaz táctil. Además, puede obtener más información en la ubicación rodeada de rojo.')
                        }
                    }
                    break;

                case "showfaqcategory":
                    commit("setQnaKeyword", "")
                    var faqKeyword = ansObj.metadata.keyword

                    // For Navigation and setting the Page header
                    router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
                    if (router.currentRoute.path !== '/faqs') {
                        router.push('/faqs')
                    }
                    var page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'faqs')
                    commit('setPageHeader', page[0].pageType[0].pageHeader)
                    //commit('setPageHeader', 'Frequently Asked Questions')

                    // The below fetchs the keyword from QNA and checks if the keyword is a category or not.
                    // If category, then search output is category
                    // Else, search is done on keyword

                    var count = i18n.t('faqCategory').filter(cat => cat.categoryName.toLowerCase() === faqKeyword)
                    if (count.length === 0) {
                        console.log('Faq Keyword: ', faqKeyword)
                        var faqs = getters.getFaqs.filter(faq => faq.category !== 'Guía SRL' && faq.category !== 'SRL Guide')
                        var linkId = faqs.filter(faq => faq.question.toLowerCase().includes(faqKeyword))
                        console.log('Faq Keyword search: ', linkId)
                        commit("setQnaKeyword", linkId[0].linkId)
                    } else {
                        commit('setSelectedFaqCat', { name: faqKeyword, type: 'expansionPanel' })
                    }
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformcategory":

                    var formKeyword = ansObj.metadata.keyword
                    router.addRoute({ path: '/forms', component: () => import('@/views/forms') })
                    if (router.currentRoute.path !== '/forms') {
                        router.push('/forms')
                    }
                    page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'forms')
                    commit('setPageHeader', page[0].pageType[0].pageHeader)
                    //commit('setPageHeader', 'Forms')
                    commit('setSelectedFormCat', { category: formKeyword })
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformpackets":
                    var packetInfo;
                    packetInfo = getters.getFormPackets.filter(pack => pack.packetName.toLowerCase() === ansObj.metadata.keyword)
                    dispatch('openPdfViewer', {
                        url: packetInfo[0].packetFiles,
                        name: packetInfo[0].packetName,
                        desc: packetInfo[0].packetDesc,
                        emailLink: packetInfo[0].packetLink,
                        type: 'Packet'
                    })
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showimage":
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showhours":
                    //dispatch('openCourtTimingViewer', {})
                    dispatch('avatarSpeak', ansObj.answer);

                    break;

                case "session":
                    var action = ansObj.metadata.find(({ name }) => name === 'action').value
                    if (action === "still there") {
                        dispatch('kioskStillThere')
                    } else {
                        dispatch('resetKiosk')
                    }
                    break;

                case "followup":

                    var prompts = ansObj.dialog.prompts
                    var followUp = [];
                    dispatch('avatarSpeak', ansObj.answer);
                    prompts.forEach(item => followUp.push(item));
                    var headerVal;
                    if (getters.getCurrentLanguage === 'en-US') {
                        headerVal = 'Tap on the below, or click on the microphone to ask another question'
                    } else {
                        headerVal = 'Pulse en el siguiente botón, o haga clic en el micrófono para hacer otra pregunta'
                    }
                    dispatch('openOptionsViewer', {
                        dialogHeader: headerVal,
                        dialogData: followUp,
                    })
                    break;
                default:
                    getters.getMaps.forEach(map => {
                        if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                            dispatch('openImageViewer', {
                                name: map.mapName,
                                url: map.mapImage
                            })
                        }
                    })
                    //dispatch('avatarSpeak', 'Sorry I’m still learning. Please ask me a different question or, get information about this topic at the Clerk’s Office located here.')
                    if (getters.getCurrentLanguage === 'en-US') {
                        dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                    } else {
                        dispatch('avatarSpeak', 'Lo siento. No estoy seguro de cómo responder esta pregunta. Por favor, hazme una pregunta diferente, o puedes continuar usando la interfaz táctil. Además, puede obtener más información en la ubicación rodeada de rojo.')
                    }
                    break;
            }
            // ToDo - Have to complete the intent based answers.
        }

    }
}
