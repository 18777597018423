<template>
    <div>
        <v-row class="mt-n7" no-gutters align="center" justify="center">
            <v-col cols="6">
                <v-card flat>
                    <v-card-text class="d-flex justify-center">
                        <v-text-field :placeholder="$i18n.t('placeHolder')" class="text-h5" hide-details outlined
                            height="55px" rounded v-model="searchKeyword" :append-icon="searchBarIcon"
                            @click:append="searchKeyword = ''">
                        </v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" align="center" v-if="searchKeyword !== '' || searchId !== ''">
                <v-card :ripple="false" class="mt-2 containerBorder overflow-y-auto" width="95%" flat :height="cardHeight">
                    <!-- No keyword matched -->
                    <v-card-text v-if="matchingSrlGuides.length === 0 && matchingFaqs.length === 0">
                        <span class="text-h4">
                            No matching Question/Keyword found.
                        </span>
                    </v-card-text>

                    <!-- Keyword matched, displaying the matched FAQs -->

                    <v-card-text v-else>
                        <v-expansion-panels class="px-6" focusable dark popout v-model="panel">
                            <v-expansion-panel v-for="(item, index) in matchingFaqs" class="mt-2" :key="index">
                                <v-expansion-panel-header color="primary" class="white--text text-h6"
                                    @click="clickedFaq(item.question)">
                                    <template v-slot:actions>
                                        <v-icon color="white" size="35">mdi-arrow-down-bold-circle</v-icon>
                                    </template>
                                    {{ item.question }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="black--text px-2 pt-2 text-justify text-h6" color="white">
                                    <v-row class="fill-height" no-gutters v-if="item.showForm">
                                        <v-col cols="9">
                                            <span v-html="item.answer"></span>
                                        </v-col>
                                        <v-col cols="3" align="center">
                                            <v-btn color="primary" @click="viewDoc(item.formName, 'form')"> {{ item.formName
                                                |
                                                trimLength
                                            }} Form</v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12">
                                            <span v-html="item.answer"></span>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <!-- Keyword matched, displaying the matched SRL Guides -->

                        <v-row class="pa-5" no-gutters>
                            <v-col v-for="item in matchingSrlGuides" :key="item.id"
                                @click="viewDoc(item.guideName, 'guide')" class="pa-3" cols="4">
                                <v-card height="80" flat class="formfolder" dark color="primary">
                                    <v-row no-gutters class="fill-height" align="center">
                                        <v-col cols="2">
                                            <v-icon size="45" color="primary lighten-4">
                                                mdi-folder-table-outline
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="10" align="start">
                                            <span class="headline">
                                                {{ item.guideName }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Default Display when the search bar is closed -->
            <v-col cols="12" v-else>
                <v-row no-gutters>
                    <v-col cols="3" class="mt-5" align="center">
                        <v-card width="95%" class="containerBorder" :height="cardHeight">
                            <v-card-title class="justify-center primary--text">{{ $t('categoryTitle') }}</v-card-title>
                            <v-divider class="mx-3"></v-divider>
                            <v-list class="overflow-y-auto" :height="cardHeight - 70">
                                <v-list-item v-for="(cat, i) in $t('faqCategory')" :key="i">
                                    <v-list-item-content>
                                        <v-btn height="45"
                                            @click="$store.commit('setSelectedFaqCat', { name: cat.categoryName, type: cat.displayType }); panel = 0"
                                            v-bind:color="getSelectedFaqCat.name.toLowerCase() === cat.categoryName.toLowerCase() ? '#15306B' : 'primary'"
                                            class="white--text">{{
                                                cat.categoryName
                                            }}</v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="9" class="mt-5" align="center">
                        <v-card width="95%" class="containerBorder overflow-y-auto" :height="cardHeight">
                            <div v-if="getSelectedFaqCat.type === 'expansionPanel'">
                                <v-expansion-panels focusable popout dark class="px-3" v-model="panel">
                                    <v-expansion-panel v-for="(item, i) in filteredFaqs()" :key="i" class="mt-2">
                                        <v-expansion-panel-header color="primary" class="white--text text-h6">
                                            <template v-slot:actions>
                                                <v-icon color="white" size="35">mdi-arrow-down-bold-circle</v-icon>
                                            </template>
                                            {{ item.question }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content color="white"
                                            class="black--text pt-4 text-justify text-h6">
                                            <v-row class="fill-height" no-gutters v-if="item.showForm">
                                                <v-col cols="9">
                                                    <span v-html="item.answer"></span>
                                                </v-col>
                                                <v-col cols="3" align="center">
                                                    <v-btn color="primary" @click="viewDoc(item.formName, 'form')"> {{
                                                        item.formName
                                                        | trimLength
                                                    }} Form</v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else>
                                                <v-col cols="12">
                                                    <span v-html="item.answer"></span>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                            <div v-else>
                                <v-row class="ma-3">
                                    <v-col cols="4" v-for="(item, i) in filteredFaqs()" :key="i">
                                        <v-card class="formfolder" dark color="primary" height="80" flat
                                            @click="viewDoc(item.guideName, 'guide')">
                                            <v-row no-gutters class="fill-height" align="center">
                                                <v-col cols="2">
                                                    <v-icon size="45" color="primary lighten-4">
                                                        mdi-folder-table-outline
                                                    </v-icon>
                                                </v-col>
                                                <v-col cols="10" align="start">
                                                    <span class="headline">
                                                        {{ item.guideName | trimLength }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import SimpleKeyboard from "@/components/SimpleKeyboard";


export default {
    name: 'faqs',
    components: {
        SimpleKeyboard,
    },
    data() {
        return {
            tab: 0,
            input: '',
            searchKeyword: '',
            searchBarIcon: 'mdi-magnify',
            cardHeight: 650,
            displayType: 'expansionPanel',
            panel: 0,
            searchId: ""
        }
    },
    computed: {
        ...mapGetters(['getQnaKeyword', 'getSelectedFaqCat']),

        // Returns matching FAQs based on searched keyword
        matchingFaqs() {
            // let temp = this.$i18n.t("faqs").filter(faq => faq.category !== "SRL Guide")
            // return temp.filter(faq => faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()))

            let temp = [];
            if (this.searchKeyword !== "") {
                temp = this.$i18n.t("faqs").filter(faq => faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()))
            } else {
                console.log('Coming in here: ', this.searchId)
                temp = this.$i18n.t("faqs").filter(faq => faq.linkId === this.searchId)
                console.log('Coming in here: ', temp)
            }
            return temp;
        },

        // Returns matching SRL Guide based on searched keyword
        matchingSrlGuides() {
            let temp = [];
            let guides = this.$i18n.t("faqs").filter(faq => faq.category === "SRL Guide")
            if (this.searchKeyword !== "") {
                temp = guides.filter(faq => faq.guideName.toLowerCase().includes(this.searchKeyword.toLowerCase()))
            } else {
                console.log('Coming in here: ', this.searchId)
                temp = guides.filter(faq => faq.linkId === this.searchId)
                console.log('Coming in here: ', temp)
            }
            return temp;
        },
    },
    watch: {
        getQnaKeyword(newVal) {
            //this.searchKeyword = newVal
            this.searchId = newVal
        },
    },
    methods: {
        onChange(input) {
            this.searchKeyword = input;
        },
        onKeyPress(button) {
            if (button === "{bksp}")
                this.searchKeyword = this.searchKeyword.slice(0, -1);
        },
        filteredFaqs() {

            return this.$i18n.t("faqs").filter(faq => faq.category.toLowerCase() === this.getSelectedFaqCat.name.toLowerCase())
        },
        viewDoc(name) {

            // Display the SRL Guide for reference to the user 
            // PDF viewer will give 2 options, pick up or email to self
            console.log('AT view doc in faqs: ', name)
            let guideArray = this.$i18n.t("faqs").filter(faq => faq.category === "SRL Guide" || faq.category === "Guía SRL")
            let isSRL = guideArray.filter(guide => guide.guideName.toLowerCase() === name.toLowerCase())
            //console.log('AT view doc in faqs: ', guideArray.length)
            if (isSRL.length > 0) {
                guideArray.forEach(guide => {
                    if (guide.guideName.toLowerCase() === name.toLowerCase()) {
                        this.$store.dispatch('openPdfViewer', {
                            url: guide.guideUrl,
                            type: 'guide',
                            name: guide.guideName,
                            emailLink: guide.guideLink
                        })
                    }
                })
            } else {
                console.log('AT view doc in faqs: ', name)
                this.$i18n.t("forms").forEach(form => {
                    if (form.formName.toLowerCase() === name.toLowerCase()) {
                        this.$store.dispatch('openPdfViewer', {
                            url: form.formPdf,
                            type: 'form',
                            name: form.formName,
                            emailLink: form.pdfUrl
                        })
                    }
                })
            }
            // Tracking Touch by the user
            this.$store.commit('setTouchRequest', {
                module: 'Frequently Asked Question',
                action: 'Show Form',
                response: name,
                timeStamp: new Date(),
                requestType: 'Touch'
            })
        },
        // The below method tracks touched faqs by the user
        clickedFaq(question) {
            this.$store.commit('setTouchRequest', {
                module: 'Frequently Asked Question',
                action: 'Show Faq',
                response: question,
                timeStamp: new Date(),
                requestType: 'Touch'
            })
        }
    },
    filters: {
        // for a good UI experience.
        trimLength(val) {
            if (val.length < 25) { return val }
            return `${val.substring(0, 20)}...`
        }
    },
    mounted() {
        this.searchId = this.getQnaKeyword
        //this.searchKeyword = this.getQnaKeyword
    }
}
</script>
<i18n>
  {
    "en": {
        "placeHolder": "Enter a keyword to search for FAQ",
        "categoryTitle": "CATEGORIES"
      },
    "es":{
        "placeHolder": "Introduzca una palabra clave para buscar preguntas frecuentes",
        "categoryTitle": "Categorías"
      }
  }
</i18n>