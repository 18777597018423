import axios from 'axios';
import i18n from "../../i18n";

export default {
    state: {
        hearingList: [],
        condition: "",
        hearingLocation: {
            'Dona Ana County Magistrate Court (CR1)': 'Courtroom 1- Judge Linda Flores',
            'Dona Ana County Magistrate Court (CR2)': 'Courtroom 2- Judge Rebecca Duffin',
            'Dona Ana County Magistrate Court (CR3)': 'Courtroom 3- Judge Norman Osborne',
            'Dona Ana County Magistrate Court (CR4)': 'Courtroom 4 - Judge Kent Wingenroth',
            'Dona Ana County Magistrate Court (CR5)': 'Courtroom 5- Judge Alex Rossario',
            'Dona Ana County Magistrate Court (CR6)': 'Courtroom 6- Judge Joel Cano',
        }
    },
    getters: {
        getHeraingList: state => state.hearingList,
        getCondition: state => state.condition,
        getHearingLocation: state => state.hearingLocation,
    },
    mutations: {
        setHearingList(state, list) {
            state.hearingList = list;
        },
        setCondition(state, condition) {
            state.condition = condition
        }
    },
    actions: {
        fetchList({ dispatch, getters, commit }) {
            return new Promise((response, reject) => {
                let cond = ""
                if (getters.getKioskProfile.data().courts.length === 1) {
                    cond = "(crt_court='" + getters.getKioskProfile.data().courts[0].toString() + "')"
                    commit("setCondition", cond)
                } else if (getters.getKioskProfile.data().courts.length === 2) {
                    cond = "(crt_court='" + getters.getKioskProfile.data().courts[0].toString() + "' OR crt_court='" + getters.getKioskProfile.data().courts[1].toString() + "')"
                    commit("setCondition", cond)
                }
                console.log('Condition: ', cond)
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/snapshotDataexchange/a763cd056f1b2405788443b7197e0708',
                    params: {
                        condition: getters.getCondition,

                    },
                    headers: {}
                };
                axios(config)
                    .then(result => {
                        // console.log(result.data);
                        dispatch('generateHearingList', result.data)
                            .then(() => {
                                response("List fetched successfully")
                            })
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error);
                    });
            })
        },
        generateHearingList({ commit, getters }, data) {
            return new Promise((response, reject) => {
                let temp = new Map();
                data.forEach((item) => {
                    if (!temp.has(item.crt_caseno)) {
                        let partyList = [];
                        let location = undefined;
                        let filteredResult = data.filter((obj) => {
                            return obj.crt_caseno === item.crt_caseno;
                        });
                        // console.log(`Case Number: ${item.crt_caseno}, Number: ${filteredResult.length}`)
                        if (filteredResult.length > 0) {
                            filteredResult.forEach(item => {
                                let fName = '';
                                let mName = '';
                                let lName = '';
                                let fullName = '';
                                if (Object.prototype.hasOwnProperty.call(item, 'crt_partyfirstname'))
                                    fName = item.crt_partyfirstname
                                if (Object.prototype.hasOwnProperty.call(item, 'crt_partymiddlename'))
                                    mName = item.crt_partymiddlename
                                if (Object.prototype.hasOwnProperty.call(item, 'crt_partylastname'))
                                    lName = item.crt_partylastname
                                fullName = fName + ' ' + mName + ' ' + lName;
                                partyList.push({
                                    firstName: fName,
                                    middleName: mName,
                                    lastName: lName,
                                    fullName: fullName.replace(/\s+/g, ' ').trim()
                                })

                            })
                            console.log('Items: ', item)
                            if (getters.getHearingLocation[item.crt_courtroom] === undefined) {
                                location = getters.getDefaultLocation
                                //hearingObj['hearingLocation'] = getters.getDefaultLocation
                            } else {
                                console.log('Hearing location: ', getters.getHearingLocation[item.crt_courtroom])
                                // i18n.t("maps").forEach(mapper => {
                                //     if (mapper.mapName.toLowerCase().trim() === getters.getHearingLocation[item.crt_courtroom].toLowerCase().trim()) {
                                //         floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapper.mapFloor)
                                //     }
                                // })
                                location = getters.getHearingLocation[item.crt_courtroom]
                                //hearingObj['hearingLocation'] = getters.getHearingLocation[hearing.HearingLocation]
                                //hearingObj['floorName'] = floorName[0].floorName
                            }
                            let mergedData = {
                                ...filteredResult[0],
                                partyList: partyList,
                                courtroomLocation: location
                            }
                            temp.set(item.crt_caseno, mergedData);
                        }
                    }
                });
                commit('setHearingList', Array.from(temp.values()))
                response('Data cleaned')
            })

        }
    }
}