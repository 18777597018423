<template>
  <v-app>
    <v-main>
      <appHeader v-if="getKioskId !== undefined"></appHeader>
      <v-fade-transition leave-absolute>
        <router-view />
      </v-fade-transition>
    </v-main>
    <pdfViewer></pdfViewer>
    <image-viewer></image-viewer>
    <email-viewer></email-viewer>
    <options-viewer></options-viewer>
    <court-timing-viewer></court-timing-viewer>
    <session-end-viewer></session-end-viewer>
    <recognition></recognition>
  </v-app>
</template>

<script>
import appHeader from '@/components/appHeader.vue';
import pdfViewer from '@/components/dialogs/pdfViewer.vue';
import imageViewer from "@/components/dialogs/imageViewer";
import emailViewer from "@/components/dialogs/emailViewer";
import optionsViewer from "@/components/dialogs/optionsViewer";
import recognition from '@/components/recognition.vue';
import courtTimingViewer from "@/components/dialogs/courtTimingViewer";
import SessionEndViewer from "./components/dialogs/sessionEndViewer.vue";
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    appHeader,
    pdfViewer,
    recognition,
    imageViewer,
    emailViewer,
    optionsViewer,
    courtTimingViewer,
    SessionEndViewer
  },
  computed: {
    ...mapGetters(['getKioskId', 'getTouchCount', 'isUserLooking']),
  },
  created() {
    window.onclick = () => {
      if (this.getTouchCount === 1 && !this.isUserLooking) {
        this.$store.dispatch('resetKiosk')
      } else if (this.getTouchCount !== 0) {
        this.$store.commit('setTouchCount', this.getTouchCount + 1)
      }
      if (this.getTouchCount > 1 && !this.isUserLooking) {
        this.$store.dispatch('clearResetTimeout').then(
          () => {
            this.$store.dispatch('resetKiosk')
          })
      }
    }
  },
  mounted() {
    //window["console"]["log"] = function () { };
    console.warn = function () { };
    //console.error = function () { };
  }
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden
}

body::-webkit-scrollbar {
  display: none;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px #6b1530;

}

.v-virtual-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.v-list::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-list::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.v-data-table::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-data-table::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.iframe::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.iframe::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.v-dialog {
  margin-top: 100px
}

.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #6b1530 !important;
  border-radius: 8px !important;
}

.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #6b1530 !important;
  border-radius: 15px !important;
}
</style>
