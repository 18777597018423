<template>
  <v-row no-gutters class="d-flex justify-space-between fill-height">
    <v-col @click="changeLanguage('en-US')" class="ml-16" cols="2">
      <v-avatar style="z-index:1" size="65">
        <v-img src="@/assets/en.png"></v-img>
      </v-avatar>
      <v-btn elevation="0" large class="ml-n9 pl-10 py-7"
        :color="getCurrentLanguage === 'en-US' ? 'primary' : 'grey lighten-2'">
        English
      </v-btn>
    </v-col>
    <v-col @click="changeLanguage('es-US')" cols="2">
      <v-avatar style="z-index:1" size="65">
        <v-img src="@/assets/es.png"></v-img>
      </v-avatar>
      <v-btn elevation="0" large class="ml-n9 pl-10 py-7"
        :color="getCurrentLanguage === 'es-US' ? 'primary' : 'grey lighten-2'">
        Spanish
      </v-btn>
    </v-col>
    <v-col @click="changeLanguage('nv-US')" cols="2">
      <v-avatar style="z-index:1" size="65">
        <v-img src="@/assets/nv.png"></v-img>
      </v-avatar>
      <v-btn elevation="0" large class="ml-n9 pl-10 py-7">
        Navajo
      </v-btn>
    </v-col>
    <v-col @click="changeLanguage('vi-US')" class="mr-16" cols="2">
      <v-avatar class="ml-n9" style="z-index:1" size="65">
        <v-img src="@/assets/vi.png"></v-img>
      </v-avatar>
      <v-btn elevation="0" large class="ml-n9 pl-10 py-7">
        Vietnamese
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'languageBar',
  computed: {
    ...mapGetters(['getTouchCount', 'getCurrentLanguage', 'getLanguages'])
  },
  data() {
    return {
      count: 0
    }
  },
  methods: {
    changeLanguage(language) {
      this.$store.commit('setTouchCount', this.getTouchCount + 1)
      this.$store.commit('setTouchRequest', {
        module: 'Language Change',
        action: 'Language changed',
        response: 'User Session Started - Touch Detected',
        timeStamp: new Date(),
        requestType: 'Session'
      })
      switch (language) {
        case 'en-US':
          this.$i18n.locale = 'en'
          this.$store.commit('setCurrentLanguage', 'en-US');
          this.$store.dispatch('avatarSpeak', 'I am now speaking in english');
          this.$store.dispatch('setDefaults', 'en')
          this.$store.commit("setSpeechRecognitionLanguage", "en-US")
          break;
        case 'es-US':
          this.$i18n.locale = 'es'
          this.$store.commit('setCurrentLanguage', 'es-US');
          this.$store.dispatch('avatarSpeak', 'Estoy hablando en español');
          this.$store.dispatch('setDefaults', 'es')
          this.$store.commit("setSpeechRecognitionLanguage", "es-US")
          break;
        case 'nv-US':
          this.$store.commit('setCurrentLanguage', 'nv-US');
          this.$store.commit('setTextBubbleText', 'Dinék’ehjí yáti’ bíhoosh’aah, t’ah hanááshidííłkiń.')
          break;
        case 'vi-US':
          this.$store.commit('setCurrentLanguage', 'vi-US');
          this.$store.commit('setTextBubbleText', 'Chúng tôi đang học tiếng Việt.  Xin quý vị vui lòng trở lại sau này.')
          break;
        default:
          break;
      }
    }
  }
}
</script>
<style></style>