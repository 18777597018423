<template>
  <v-dialog v-model="getOptionsViewer.show" width="67%" persistent>
    <v-card class="pa-5 align-center justify-center">
      <v-row no-gutters>
        <v-col cols="1" align-self="center" class="pa-0 ma-0">
          <!-- <div style="position: absolute; align-self: end; left: 0; top: 0"> -->
          <v-img @click="actionOnTap()" class="talk-button mt-1 ml-3" contain width="90px"
            :src="require('../../assets/' + talkButtonSrc)">
          </v-img>
          <!-- </div> -->
        </v-col>
        <v-col class="pa-0 ma-0" align="center">
          <v-card-text class="justify-center text-h4 font-weight-medium black--text px-5">
            {{ getOptionsViewer.dialogHeader }}
          </v-card-text>
        </v-col>
        <v-col cols="1" class="pa-0 ma-0" align="right">
          <v-btn icon @click="closeDialog()" class="mt-1">
            <v-icon color="red" x-large>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>


      <div style="position: absolute; align-self: end; right: 0; top: 0">

      </div>
      <v-card-text align="center" class="pa-0">
        <v-card v-for="(item, index) in getOptionsViewer.dialogData" :key="index" class="my-1" width="85%"
          @click="viewElement(item)" color="primary">
          <v-card-text class="white--text text-h5">
            <span v-if="typeof item === 'object'">
              {{ item.displayText }}
            </span>
            <span v-else>
              {{ item }}
            </span>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "optionsViewer",
  data() {
    return {
      talkButtonSrc: "talkButton.png",
      speakingStatus: "Tap To Talk",
    };
  },
  computed: {
    ...mapGetters([
      "getOptionsViewer",
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getResetKioskStatus",
    ]),
  },
  methods: {
    viewElement(input) {
      // Touch Usage
      this.$store.commit("setTouchRequest", {
        module: "Options Viewer",
        action: input,
        response: "Action executed on touch",
        timeStamp: new Date(),
        requestType: "Touch",
      });
      this.$store.dispatch("requestQnA", input);
      this.$store.commit("closeOptionsViewer");
    },
    closeDialog() {
      this.$store.commit("closeOptionsViewer");
      this.$store.dispatch("kioskStillThere");
    },
    actionOnTap() {
      if (!this.getIsSpeaking) {
        if (this.getResetKioskStatus) {
          this.$store.commit("setSpeechRecognitionHandler", true);
        } else {
          this.$store.commit("setSpeechRecognitionHandler", true);
        }
      } else {
        window.stopSpeech();
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
  watch: {
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
};
</script>

<style>
.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
}
</style>
